import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const UndergraduateWritingServicePage = () => (
  <Layout
    pageWrapperClassName="undergraduate-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Undergraduate Writing Service - Write My Undergraduate Essay"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is an Undergraduate Essay Writing Service that provides top-notch quality papers written by professionals. Customers can enjoy around-the-clock customer undergraduate writing service, secure money-back guarantees, and read verified reviews of satisfied clients.',
        },

        {
          property: 'og:title',
          content: 'Custom Undergraduate Essay Writing Service: The Best College Papers for Sale | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'With our custom undergraduate essay writing service, you don’t have to worry about all the homework you have got to do: we are going to help you with all the essays and papers that were assigned to you. Just place your order on our website, and we will contact you back right after that!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/undergraduate-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        High school essays
        <br className="first-screen-section__title__br" />
        {' '}
        giving you a hard time?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Check out our service.
        <br className="first-screen-section__heading__br" />
        {' '}
        We sell essays that can get you high grades.
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Write my undergraduate essay: undergraduate writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Do you need someone to help you out with your undergraduate essay? Our undergraduate
            essay writing service is ready to assist you with all the papers you have to write. All
            you have to do is just send us a message with the details of your order, and we will
            start writing it immediately. Here on this page, you can find all the information you
            need to know about our company and the services we have to offer.
          </p>
          <h2 className="h3">Buy undergraduate essay and assignments of any type with us</h2>
          <p>
            The best helper is ready to start working on your assignment now. Here are some of the
            papers we work on as well:
          </p>
          <ul className="dot-list">
            <li>Dissertation</li>
            <li>College research and term papers</li>
            <li>Case studies</li>
            <li>Business plans</li>
            <li>Reports</li>
            <li>Reviews</li>
            <li>Summary</li>
            <li>Analysis</li>
            <li>Any type of paper</li>
          </ul>
          <p>
            “Write my undergraduate essay” is the only thing you should send us in order to get the
            assistance you need. Purchase papers of the best quality on our website and get the
            assignment done by the professional writer.
          </p>
          <h2 className="h3">Why should I rely on you to write my undergraduate essay? </h2>
          <p>
            <strong>
              With our online writing service, you can finally get the assistance with your papers
              that you always needed. When you buy undergraduate essay with our company, you receive
              not only an assignment written in perfect English and free from plagiarism, but also
              help with all the academics and subjects you didn’t know how to deal with.
            </strong>
          </p>
          <p>
            Our college helper will assist you with even the hardest task, you can be sure about
            that. Here are some of the advantages of choosing our custom undergraduate essay writing
            service:
          </p>
          <ol>
            <li>
              Real experts. With us, you can hire a professional who will be able to complete an
              assignment of any complexity. We work with professionals from around the globe, and
              all of them have one thing in common: the high level of skills and knowledge. The best
              specialists are going to write a high-quality paper for you.
            </li>
            <li>
              Excellent quality. Every order that we receive is taken responsibly. We know how
              important it is to get a good grade for your essay, so our writer will compose for you
              an outstanding paper you will be able to use for your benefit.
            </li>
            <li>
              Affordable services. We have got many cheap essays and papers for sale, so if you want
              to save your money and buy your assignments at the best price, now is the time to do
              that. We offer many good deals on all kinds of papers, so get the one you need with
              our service today.
            </li>
            <li>
              Full safety of your private data. You won’t have to take any risks when using our
              custom undergraduate essay writing service. Your private data is protected thanks to
              the GDPR and our privacy policy. All the messages and orders are confidential.
            </li>
            <li>
              Legit custom assignments. All the papers you will get with us are done by a
              professional. None of them are pre-written. This means that you will get a paper that
              is authentic and non-plagiarized. Our undergraduate essay writing service will become
              a source of inspiration for your own papers.
            </li>
            <li>
              Urgent assignments. All of the papers we complete are done fast. Right after you make
              your order, our writers start working on your assignment. We know how important it is
              to get done with all the tasks on time, and this is why we provide all of our
              customers with quick delivery of all orders.
            </li>
          </ol>
          <h2 className="h3">
            Work with professionals today: pay for undergraduate essay without doubts{' '}
          </h2>
          <p>
            “Who can do my undergraduate essay? Can I trust someone to do my papers for me?” are the
            questions we often receive. All of the paid assignments and custom undergraduate essays
            that we write are always done on the best level possible.
          </p>
          <p>
            Don’t pay a lot of money to get done with all the papers: now, you can get cheap
            assignments with the service that is rated as one of the best on the market. Buy
            undergraduate essay online that will help you become a better student and achieve the
            academic heights you always dreamed about.
          </p>
          <h2 className="h3">Buy a paper of the top quality now</h2>
          <p>
            If you have a paper to write, you can request our help at any moment. Every day, more
            and more students decide to turn to us for help, and none of them is going to be let
            down. No matter how complicated your paper is, our professionals will take care of it.
            Your assignment will be in good hands.
          </p>
          <p>
            On our website, you can find all the detailed information that you need to know about
            our service. If there are any concerns or questions left, feel free to discuss them with
            us. 24/7, our support team is ready to assist you.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      The sky is no longer the limit.
      <br />
      {' '}
      Skyrocket your grades!
    </CTA>

  </Layout>
);

export default UndergraduateWritingServicePage;
